//@ts-nocheck

const fetchFarm = async (farm) => {

  return {
 
    tokenAmountTotal: '0',
    lpTotalSupply:  '0',
    lpTotalInQuoteToken: '0',
    tokenPriceVsQuote:  '0',
    poolWeight:  '0',
  }
}

export default fetchFarm
