import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'PancakeSwap',
  description:
    'The most popular AMM on BTC by user count! Earn BHV through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PancakeSwap), NFTs, and more, on a platform you can trust.',
  image: 'https://pancakeswap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('HiveSwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('HiveSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('HiveSwap')}`,
      }


    default:
      return null
  }
}
