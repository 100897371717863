import React from 'react'
import { Menu as UikitMenu } from '@pancakeswap/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/farms/hooks'
import { useWeb3React } from '@web3-react/core'
import config from './config'
import config1 from './config1'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'

const Menu = (props) => {
  const { account } = useWeb3React()
  // const whiteList = [
  //   '0xfc282d2BfC0B38A93034Ad06dc467C2b1A768E32',
  //   '0x01EB1a70B4B3529fC0C3a8dc6f78ffb5F5d42E8c',
  //   '0xd73d348055E41bb405054720c4a06133bd004CD5',
  // ]
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()

  const { currentLanguage, setLanguage, t } = useTranslation()
  // const menuConfig = account && whiteList.includes(account) ? config : config1

  return (
    <UikitMenu
      userMenu={<UserMenu />}
      globalMenu={<GlobalSettings />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config(t)}
      profile={null}
      {...props}
    />
  )
}

export default Menu
