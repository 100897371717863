export default {
  masterChef: {
    11501: '0x3Bb616F49089ef0F76BC95b4C14758F8C943022D',
  },
  pools2: {
    11501: '0x3Bb616F49089ef0F76BC95b4C14758F8C943022D',
  },
  multiCall: {
    11501: '0x3Bb616F49089ef0F76BC95b4C14758F8C943022D',
  },
  cakeVault: {
    11501: '0x3Bb616F49089ef0F76BC95b4C14758F8C943022D',
  },

}
