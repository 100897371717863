import { ChainId, Token } from '@pancakeswap/sdk'

// export const BHV: { [chainId: number]: Token } = {
//   [ChainId.MAINNET]: new Token(
//       ChainId.MAINNET,
//       '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
//       18,
//       'BHV',
//       'PancakeSwap Token',
//   ),
// }
//cake换成bhv
export const USDT: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
      ChainId.MAINNET,
      '0xF2692468666E459D87052f68aE474E36C1a34fbB',
      18,
      'SAT',
      'Statoshi Stablecoin',
  ),
}

//busd换成usdt
export const WBTC = new Token(ChainId.MAINNET, '0xB5136FEba197f5fF4B765E5b50c74db717796dcD', 18, 'WBTC', 'Wrapped BTC')
//wbnb换成wtre
// export const USDT1 = new Token(ChainId.MAINNET, '0xB5136FEba197f5fF4B765E5b50c74db717796dcD', 18, 'USDT', 'USDT')

const tokens = {
  true: {
    symbol: 'BTC',
    projectLink: 'https://www.binance.com/',
  },
  wbtc: {
    symbol: 'WBTC',
    address: {
      11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
    },
    decimals: 18,
    projectLink: 'https://scan-mainnet.bevm.io/',
  },
  stbtc: {
    symbol: 'stBTC',
    address: {
      11501: '0x26bda683F874e7AE3e3A5d3fad44Bcb82a7c107C',
    },
    decimals: 18,
    projectLink: 'https://scan-mainnet.bevm.io/',
  },
  sat: {
    symbol: 'SAT',
    address: {
      11501: '0xF2692468666E459D87052f68aE474E36C1a34fbB',
    },
    decimals: 18,
    projectLink: 'https://scan-mainnet.bevm.io/',
  },
  mstl: {
    symbol: 'MSTL',
    address: {
      11501: '0x037b572661137537611F707CFA3F206994767e23',
    },
    decimals: 18,
    projectLink: 'https://scan-mainnet.bevm.io/',
  },
  // usdc: {
  //   symbol: 'USDC',
  //   address: {
  //     11501: '0xC4e4cb94AB26fF3ee9F971d85A4114B14E6fcB72',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://scan-mainnet.bevm.io/',
  // },
  // usdt: {
  //   symbol: 'USDT',
  //   address: {
  //     11501: '0x08d115D02a7dcE1Ebc404C1f92916A6F002f30Bc',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://scan-mainnet.bevm.io/',
  // },



  // hive: {
  //   symbol: 'BHV',
  //   address: {
  //     11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // // cake换成bhv
  // befi: {
  //   symbol: 'BEFI',
  //   address: {
  //     11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // map: {
  //   symbol: 'BTC',
  //   address: {
  //     11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // bhv: {
  //   symbol: 'BHV',
  //   address: {
  //     11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // syrubar: {
  //   symbol: 'SYRUBAR',
  //   address: {
  //     11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // wbnb: {
  //   symbol: 'WBTC',
  //   address: {
  //     11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // bhv_true: {
  //   symbol: 'BHV-BTC',
  //   address: {
  //     11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // bhv_usdt: {
  //   symbol: 'BHV-USDT',
  //   address: {
  //     11501: '0xB5136FEba197f5fF4B765E5b50c74db717796dcD',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },

  // eth: {
  //   symbol: 'ETH',
  //   address: {
  //     11501: '0x05ab928d446d8ce6761e368c8e7be03c3168a9ec',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  //
  // idv: {
  //   symbol: 'IDV',
  //   address: {
  //     11501: '0xeAc6cFD6e9e2FA033d85b7ABdb6B14FE8aA71f2A',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // xidv: {
  //   symbol: 'xIDV',
  //   address: {
  //     11501: '0x868031997cbC1FCA49110d072A31a8cBdaE248a2',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // gxidv: {
  //   symbol: 'gxIDV',
  //   address: {
  //     11501: '0x938cC81D4c5FbD73de3078dEdF19a14Cb2e7fdDA',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  //
  // yxidv: {
  //   symbol: 'yxIDV',
  //   address: {
  //     11501: '0x86516E8baC5fD9D2cdAeE81c1CAD1f7bB3d0404d',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // xmapo: {
  //   symbol: 'xMAPO',
  //   address: {
  //     11501: '0x718cf5e66ae50b29a3b5ce6357e22acccf3a88dc',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // yxmapo: {
  //   symbol: 'yxMAPO',
  //   address: {
  //     11501: '0x507de63e7fb45ed3589c09c2109c39e30b535edf',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
  // gxmapo: {
  //   symbol: 'gxMAPO',
  //   address: {
  //     11501: '0x85816b1dcdb707812208557d6f271183f58aa525',
  //   },
  //   decimals: 18,
  //   projectLink: 'https://pancakeswap.finance/',
  // },
}

export default tokens
