//@ts-nocheck
import tokens from './tokens'

const farms = [
  {
    pid: 1,
    lpSymbol: 'MAPO-USDC LP',
    lpAddresses: {
      22776: '0x6afa57f92fcef4035b4efb7ef0c162718da23ba9',
    },
    contractsAddresses: '0x39985A5ba47C5e89F4f6220a00e3b11B7Ba5AAf8',
    token: tokens.usdc,
    quoteToken: tokens.map,
    multiplier: '1X',
  },
  {
    pid: 2,
    lpSymbol: 'MAPO-ETH LP',
    lpAddresses: {
      22776: '0x273abbca7aab6cc8944dcaa413a3823ec5e60134',
    },
    contractsAddresses: '0xA618A3fE7171e36F899D799A50b3556dc231552E',
    token: tokens.eth,
    quoteToken: tokens.map,
    multiplier: '3X',
  },
  {
    pid: 3,
    lpSymbol: 'IDV-MAPO LP',
    lpAddresses: {
      22776: '0x92127d77906ba0482141094dc67a8121fd5e901f',
    },
    contractsAddresses: '0x91207D15F83d9CCa73246508c13b56E542cE9331',
    token: tokens.map,
    quoteToken: tokens.idv,
    multiplier: '3X',
  },
  {
    pid: 4,
    lpSymbol: 'xIDV-IDV LP',
    lpAddresses: {
      22776: '0xfe130b55d7e0bebe0a84c7559af21ff92598cfd5',
    },
    contractsAddresses: '0xFF5c9DAE24604C2B11DC819ea7a9411bcAe28Db1',
    token: tokens.xidv,
    quoteToken: tokens.idv,
    multiplier: '3X',
  },

  {
    pid: 5,
    lpSymbol: 'gxIDV-xIDV LP',
    lpAddresses: {
      22776: '0x3fbf14295d93a20d10a1579b4dc7a1dd725b9801',
    },
    contractsAddresses: '0xcbeC75263A3a20E7DCc92596D148434DfEBCe0A9',
    token: tokens.gxidv,
    quoteToken: tokens.idv,
    multiplier: '3X',
  },
  {
    pid: 6,
    lpSymbol: 'yxIDV-xIDV LP',
    lpAddresses: {
      22776: '0xed65c10bb8b2c1a118f7079b79b080f3c13cba3b',
    },
    contractsAddresses: '0xCB0428dfB780d0f89D33C385Fd60aC78752Ebd8c',
    token: tokens.yxidv,
    quoteToken: tokens.idv,
    multiplier: '3X',
  },
  {
    pid: 7,
    lpSymbol: 'MAPO-xMAPO LP',
    lpAddresses: {
      22776: '0xbe044b1ee50c05d88211b8da4737fb86aa6e6e1d',
    },
    contractsAddresses: '0x1e2aa124385016f9bd3ed87d21d907fac21fe707',
    token: tokens.map,
    quoteToken: tokens.map,
    multiplier: '3X',
  },
  {
    pid: 8,
    lpSymbol: 'yxMAPO-xMAPO LP',
    lpAddresses: {
      22776: '0xaa8514208f06b9168b0cb345ded5483ab37440ed',
    },
    contractsAddresses: '0x4a27973cdbaf56a7838865d3970a9f8d3086323e',
    token: tokens.yxmapo,
    quoteToken: tokens.map,
    multiplier: '3X',
  },
  {
    pid: 9,
    lpSymbol: 'gxMAPO-xMAPO LP',
    lpAddresses: {
      22776: '0xde2d1b5f77514791b89a09ee90179dafd01692ae',
    },
    contractsAddresses: '0xe63af737369ed178857e9699eba99047978394fb',
    token: tokens.gxmapo,
    quoteToken: tokens.map,
    multiplier: '3X',
  },


]

export default farms
