//@ts-nocheck
import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import { Route, useRouteMatch, useLocation, NavLink } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Image, Heading, RowType, Toggle, Text, Button, ArrowForwardIcon, Flex } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import styled from 'styled-components'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import { useFarms, usePollFarmsData, usePriceCakeBusd, useUpdateFarmsList } from 'state/farms/hooks'
import usePersistState from 'hooks/usePersistState'
import { Farm } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { getFarmApr } from 'utils/apr'
import { orderBy } from 'lodash'
import { latinise } from 'utils/latinise'
import { useUserFarmStakedOnly } from 'state/user/hooks'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import Loading from 'components/Loading'
import FarmCard, { FarmWithStakedValue } from './components/FarmCard/FarmCard'
import Table from './components/FarmTable/FarmTable'
import FarmTabButtons from './components/FarmTabButtons'
import { RowProps } from './components/FarmTable/Row'
import ToggleView from './components/ToggleView/ToggleView'
import { DesktopColumnSchema, ViewMode } from './components/types'

const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 32px;
    margin-bottom: 0;
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  ${Text} {
    margin-left: 8px;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0;
    }
  }
`

const StyledImage = styled(Image)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 58px;
`
const NUMBER_OF_FARMS_VISIBLE = 12

const getDisplayApr = (cakeRewardsApr?: number, lpRewardsApr?: number) => {
  if (cakeRewardsApr && lpRewardsApr) {
    return (cakeRewardsApr + lpRewardsApr).toLocaleString('en-US', { maximumFractionDigits: 2 })
  }
  if (cakeRewardsApr) {
    return cakeRewardsApr.toLocaleString('en-US', { maximumFractionDigits: 2 })
  }
  return null
}

const Farms: React.FC = () => {
  // const { path } = useRouteMatch()
  //
  // const isPools2 = useMemo(() => {
  //   return path === '/pools2'
  // }, [path])
  // usePollFarmsData(isPools2)
  // useUpdateFarmsList(isPools2)
  //
  // const { pathname } = useLocation()
  //
  // const { t } = useTranslation()
  // const { data: farmsLP, userDataLoaded } = useFarms()
  //
  // const cakePrice = usePriceCakeBusd()
  // const [query, setQuery] = useState('')
  // const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, { localStorageKey: 'pancake_farm_view' })
  // const { account } = useWeb3React()
  // const [sortOption, setSortOption] = useState('hot')
  // const chosenFarmsLength = useRef(0)
  //
  // const isArchived = pathname.includes('archived')
  // const isInactive = pathname.includes('history')
  // const isActive = !isInactive && !isArchived
  //
  // // Users with no wallet connected should see 0 as Earned amount
  // // Connected users should see loading indicator until first userData has loaded
  // const userDataReady = !account || (!!account && userDataLoaded)
  //
  // const [stakedOnly, setStakedOnly] = useUserFarmStakedOnly(isActive)
  //
  // const activeFarms = farmsLP.filter((farm) => farm.multiplier !== '0X')
  // const inactiveFarms = farmsLP.filter((farm) => farm.multiplier === '0X')
  //
  // const stakedOnlyFarms = activeFarms.filter(
  //   (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  // )
  //
  // const stakedInactiveFarms = inactiveFarms.filter(
  //   (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  // )
  // const farmsList = useCallback(
  //   (farmsToDisplay: Farm[]): FarmWithStakedValue[] => {
  //     let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
  //       if (!farm.lpTotalInQuoteToken || !farm.quoteToken.busdPrice) {
  //         return farm
  //       }
  //       const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(farm.quoteToken.busdPrice)
  //       const { cakeRewardsApr, lpRewardsApr } = isActive
  //         ? getFarmApr(new BigNumber(farm.poolWeight), cakePrice, totalLiquidity, farm.lpAddresses[ChainId.MAINNET])
  //         : { cakeRewardsApr: 0, lpRewardsApr: 0 }
  //
  //       return { ...farm, apr: cakeRewardsApr, lpRewardsApr, liquidity: totalLiquidity }
  //     })
  //
  //     if (query) {
  //       const lowercaseQuery = latinise(query.toLowerCase())
  //       farmsToDisplayWithAPR = farmsToDisplayWithAPR.filter((farm: FarmWithStakedValue) => {
  //         return latinise(farm.lpSymbol.toLowerCase()).includes(lowercaseQuery)
  //       })
  //     }
  //     return farmsToDisplayWithAPR
  //   },
  //   [cakePrice, query, isActive],
  // )
  //
  // const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setQuery(event.target.value)
  // }
  //
  // const loadMoreRef = useRef<HTMLDivElement>(null)
  //
  // const [numberOfFarmsVisible, setNumberOfFarmsVisible] = useState(NUMBER_OF_FARMS_VISIBLE)
  // const [observerIsSet, setObserverIsSet] = useState(false)
  //
  // const chosenFarmsMemoized = useMemo(() => {
  //   let chosenFarms = []
  //
  //   const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
  //     switch (sortOption) {
  //       case 'apr':
  //         return orderBy(farms, (farm: FarmWithStakedValue) => farm.apr + farm.lpRewardsApr, 'desc')
  //       case 'multiplier':
  //         return orderBy(
  //           farms,
  //           (farm: FarmWithStakedValue) => (farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0),
  //           'desc',
  //         )
  //       case 'earned':
  //         return orderBy(
  //           farms,
  //           (farm: FarmWithStakedValue) => (farm.userData ? Number(farm.userData.earnings) : 0),
  //           'desc',
  //         )
  //       case 'liquidity':
  //         return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
  //       default:
  //         return farms
  //     }
  //   }
  //
  //   if (isActive) {
  //     chosenFarms = stakedOnly ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
  //   }
  //   if (isInactive) {
  //     chosenFarms = stakedOnly ? farmsList(stakedInactiveFarms) : farmsList(inactiveFarms)
  //   }
  //
  //   return sortFarms(chosenFarms).slice(0, numberOfFarmsVisible)
  // }, [
  //   sortOption,
  //   activeFarms,
  //   farmsList,
  //   inactiveFarms,
  //   isActive,
  //   isInactive,
  //
  //   stakedInactiveFarms,
  //   stakedOnly,
  //   stakedOnlyFarms,
  //   numberOfFarmsVisible,
  // ])
  //
  // chosenFarmsLength.current = chosenFarmsMemoized.length
  //
  // useEffect(() => {
  //   const showMoreFarms = (entries) => {
  //     const [entry] = entries
  //     if (entry.isIntersecting) {
  //       setNumberOfFarmsVisible((farmsCurrentlyVisible) => {
  //         if (farmsCurrentlyVisible <= chosenFarmsLength.current) {
  //           return farmsCurrentlyVisible + NUMBER_OF_FARMS_VISIBLE
  //         }
  //         return farmsCurrentlyVisible
  //       })
  //     }
  //   }
  //
  //   if (!observerIsSet) {
  //     const loadMoreObserver = new IntersectionObserver(showMoreFarms, {
  //       rootMargin: '0px',
  //       threshold: 1,
  //     })
  //     loadMoreObserver.observe(loadMoreRef.current)
  //     setObserverIsSet(true)
  //   }
  // }, [chosenFarmsMemoized, observerIsSet])
  //
  // const rowData = chosenFarmsMemoized.map((farm) => {
  //   const { token, quoteToken } = farm
  //   const tokenAddress = token.address
  //   const quoteTokenAddress = quoteToken.address
  //   const lpLabel = farm.lpSymbol && farm.lpSymbol.split(' ')[0].toUpperCase().replace('PANCAKE', '')
  //   const row: RowProps = {
  //     apr: {
  //       value: getDisplayApr(farm.apr, farm.lpRewardsApr),
  //       pid: farm.pid,
  //       multiplier: farm.multiplier,
  //       lpLabel,
  //       lpSymbol: farm.lpSymbol,
  //       tokenAddress,
  //       quoteTokenAddress,
  //       cakePrice,
  //       originalValue: farm.apr,
  //     },
  //     lpAddresses: farm.lpAddresses,
  //     farm: {
  //       label: lpLabel,
  //       pid: farm.pid,
  //       token: farm.token,
  //       quoteToken: farm.quoteToken,
  //       earnToken1: isPools2 ? farm.earnToken1 : null,
  //       earnToken2: isPools2 ? farm.earnToken2 : null,
  //     },
  //     earned: {
  //       earnings: !isPools2 ? getBalanceNumber(farm.userData?.earnings) : '',
  //       pid: farm.pid,
  //     },
  //     earnings1: isPools2 ? getBalanceNumber(farm.userData?.earnings?.earnings1) : '',
  //     earnings2: isPools2 ? getBalanceNumber(farm.userData?.earnings?.earnings2) : '',
  //
  //     liquidity: {
  //       liquidity: farm.liquidity,
  //     },
  //     multiplier: {
  //       multiplier: farm.multiplier,
  //     },
  //     details: farm,
  //   }
  //
  //   return row
  // })
  //
  // const renderContent = (): JSX.Element => {
  //   if (viewMode === ViewMode.TABLE && rowData.length) {
  //     const columnSchema = DesktopColumnSchema
  //
  //     const columns = columnSchema.map((column) => ({
  //       id: column.id,
  //       name: column.name,
  //       label: column.label,
  //       sort: (a: RowType<RowProps>, b: RowType<RowProps>) => {
  //         switch (column.name) {
  //           case 'farm':
  //             return b.id - a.id
  //           case 'apr':
  //             if (a.original.apr.value && b.original.apr.value) {
  //               return Number(a.original.apr.value) - Number(b.original.apr.value)
  //             }
  //
  //             return 0
  //           case 'earned':
  //             return a.original.earned.earnings - b.original.earned.earnings
  //
  //           default:
  //             return 1
  //         }
  //       },
  //       sortable: column.sortable,
  //     }))
  //
  //     return <Table data={rowData} columns={columns} userDataReady={userDataReady} />
  //   }
  //
  //   return (
  //     <FlexLayout>
  //       <Route exact path={`${path}`}>
  //         {chosenFarmsMemoized.map((farm) => (
  //           <FarmCard
  //             key={farm.pid}
  //             farm={farm}
  //             displayApr={getDisplayApr(farm.apr, farm.lpRewardsApr)}
  //             cakePrice={cakePrice}
  //             account={account}
  //             removed={false}
  //           />
  //         ))}
  //       </Route>
  //       <Route exact path={`${path}/history`}>
  //         {chosenFarmsMemoized.map((farm) => (
  //           <FarmCard
  //             key={farm.pid}
  //             farm={farm}
  //             displayApr={getDisplayApr(farm.apr, farm.lpRewardsApr)}
  //             cakePrice={cakePrice}
  //             account={account}
  //             removed
  //           />
  //         ))}
  //       </Route>
  //       <Route exact path={`${path}/archived`}>
  //         {chosenFarmsMemoized.map((farm) => (
  //           <FarmCard
  //             key={farm.pid}
  //             farm={farm}
  //             displayApr={getDisplayApr(farm.apr, farm.lpRewardsApr)}
  //             cakePrice={cakePrice}
  //             account={account}
  //             removed
  //           />
  //         ))}
  //       </Route>
  //     </FlexLayout>
  //   )
  // }
  //
  // const handleSortOptionChange = (option: OptionProps): void => {
  //   setSortOption(option.value)
  // }
  //
  // return (
  //   <>
  //     <PageHeader>
  //       <Heading as="h1" scale="xxl" color="secondary" mb="24px">
  //         { t('Farms')}
  //       </Heading>
  //       <Heading scale="lg" color="text">
  //         {t('Earn Token using your Liquidity Pool (LP) tokens, high APR applied.')}
  //       </Heading>
  //     </PageHeader>
  //     <Page>
  //       <ControlContainer>
  //         <ViewControls>
  //           <ToggleView viewMode={viewMode} onToggle={(mode: ViewMode) => setViewMode(mode)} />
  //           <ToggleWrapper>
  //             <Toggle checked={stakedOnly} onChange={() => setStakedOnly(!stakedOnly)} scale="sm" />
  //             <Text> {t('Staked only')}</Text>
  //           </ToggleWrapper>
  //           <FarmTabButtons hasStakeInFinishedFarms={stakedInactiveFarms.length > 0} />
  //         </ViewControls>
  //         <FilterContainer>
  //           <LabelWrapper>
  //             <Text textTransform="uppercase">{t('Sort by')}</Text>
  //             <Select
  //               options={[
  //                 {
  //                   label: t('Hot'),
  //                   value: 'hot',
  //                 },
  //                 {
  //                   label: t('APR'),
  //                   value: 'apr',
  //                 },
  //                 // {
  //                 //   label: t('Multiplier'),
  //                 //   value: 'multiplier',
  //                 // },
  //                 {
  //                   label: t('Earned'),
  //                   value: 'earned',
  //                 },
  //                 {
  //                   label: t('Liquidity'),
  //                   value: 'liquidity',
  //                 },
  //               ]}
  //               onChange={handleSortOptionChange}
  //             />
  //           </LabelWrapper>
  //           <LabelWrapper style={{ marginLeft: 16 }}>
  //             <Text textTransform="uppercase">{t('Search')}</Text>
  //             <SearchInput onChange={handleChangeQuery} placeholder="Search Farms" />
  //           </LabelWrapper>
  //         </FilterContainer>
  //       </ControlContainer>
  //       {renderContent()}
  //       {account && !userDataLoaded && stakedOnly && (
  //         <Flex justifyContent="center">
  //           <Loading />
  //         </Flex>
  //       )}
  //       <div ref={loadMoreRef} />
  //       {/* <StyledImage src="/images/decorations/3dpan.png" alt="Pancake illustration" width={120} height={103} /> */}
  //     </Page>
  //   </>
  // )

  return (
      <>
      </>
  )
}

export default Farms
