// const PANCAKE_EXTENDED = 'https://bafybeiaajylwwxlgovoxw64cj75hievu3uwaqtlc5jyrpgp2ivxtmyj4wy.ipfs.nftstorage.link/hiveswap-default.tokenlist.json'
// const PANCAKE_TOP100 = 'https://bafybeiaajylwwxlgovoxw64cj75hievu3uwaqtlc5jyrpgp2ivxtmyj4wy.ipfs.nftstorage.link/hiveswap-default.tokenlist.json'


// const PANCAKE_EXTENDED = 'https://bafybeicpmwqr343rlw3zubjqtfbjimw7set4w3kmcecaepjmwluyxxnc4e.ipfs.nftstorage.link/miswap.tokenlist.json'
// const PANCAKE_TOP100 = 'https://bafybeicpmwqr343rlw3zubjqtfbjimw7set4w3kmcecaepjmwluyxxnc4e.ipfs.nftstorage.link/miswap.tokenlist.json'

const PANCAKE_EXTENDED = 'https://bafybeigcqdlyxpyvo6rtr7xu4yk6hpg2ej5bk6kksyzgpjzku7zvc2ptmm.ipfs.nftstorage.link/Bitsea.tokenlist_BEVM_Mainnet.json'
const PANCAKE_TOP100 = 'https://bafybeigcqdlyxpyvo6rtr7xu4yk6hpg2ej5bk6kksyzgpjzku7zvc2ptmm.ipfs.nftstorage.link/Bitsea.tokenlist_BEVM_Mainnet.json'


export const UNSUPPORTED_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  PANCAKE_TOP100,
  PANCAKE_EXTENDED,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
