import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  // {
  //   label: t('Farms'),
  //   icon: 'FarmIcon',
  //   href: '/farms',
  // },
  // {
  //   label: t('Farms2'),
  //   icon: 'FarmIcon',
  //   href: '/pools2',
  // },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  // {
  //   label: t('Pools'),
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },

  {
    label: t('Info'),
    icon: 'InfoIcon',
    href: '/info',
  },

  {
    label: t('NFT Market'),
    icon: 'NftMarIcon',
    href: 'https://bitsea.ink/#/home',
  },

  {
    label: t('Bridge'),
    icon: 'BridgeIcon',
    href: 'https://bevm.io/bridge-mainnet',
  },
]

export default config
